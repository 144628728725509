@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600;800&display=swap');

body {
    background-color: #0f1f50;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.hero-section {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    max-width: 1400px;
    margin: auto;
    margin-top: 150px;
    min-height: 100vh;
    position: relative;
}

.content-wrapper {
    flex: 1;
    min-width: 300px;
    max-width: 700px;
    margin-right: 40px;
}

.image-wrapper {
    flex: 0 0 450px;
    margin-left: auto;
}

.profile-image {
    width: 100%;
    margin-top: 20px;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

.hero-section h1 {
    font-size: clamp(40px, 5vw, 64px);
    color: #cfe2e3;
    margin-bottom: -40px;
}

.hero-section h2 {
    color: #91abac;
    font-size: clamp(32px, 4vw, 64px);
    margin-bottom: 40px;
}

.hero-section h4 {
    color: rgb(47, 158, 73);
    font-weight: 600;
    margin-bottom: -10px;
    font-size: 18px;
    font-family: Courier, monospace;
    margin-bottom: -20px;
}

.hero-section p {
    color: rgb(180, 180, 180);
    font-weight: 400;
    font-family: Geneva, sans-serif;
    line-height: 25px;
}

.special {
    color: rgb(47, 158, 73);
}

.nav-words {
    margin: 30px 0;
    display: flex;
    gap: 40px;
    color: #f1f6f7;
    font-size: 18px;
    font-weight: 600;
    flex-wrap: wrap;
}

.link {
    cursor: pointer;
    transition: color 0.3s ease;
    color: #f1f6f7; /* Add this to make links white */
    text-decoration: none; /* Remove default underline */
}

.link:hover {
    text-decoration: underline;
}

.nav-words .separator {
    margin: 0 -30px;
    user-select: none;
}

.social-icons {
    margin-top: 10px;
    position: relative;
    height: 60px;  /* Set a fixed height to contain the absolutely positioned icons */
    width: 330px;  /* Set a fixed width to match the total width needed for all icons */
}

/* Media Queries */
@media (max-width: 1200px) {
    .hero-section {
        width: 95%;
    }

    .hero-section h1 {
        margin-bottom: -20px;
    }
    
    .content-wrapper {
        margin-right: 20px;
    }
    
    .image-wrapper {
        flex: 0 0 350px;
    }
}

@media (max-width: 768px) {
    .hero-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 50px;
    }
    
    .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        max-width: 100%;
    }

    .hero-section p {
        text-align: center;
    }

    .hero-section h1 {
        margin-bottom: -10px;
    }

    .nav-words {
        justify-content: center;
    }

    .social-icons {
        justify-content: center;
    }
    
    .image-wrapper {
        flex: 0 0 auto;
        margin-left: 0;
        margin-top: 40px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .profile-image {
        max-height: 400px;
        width: 100%;
        object-fit: contain;
    }
}

.mobile-image-wrapper {
    display: none;
}

@media (max-width: 768px) {
    .mobile-image-wrapper {
        display: block;
        width: 100%;
        max-width: 400px;
        margin: 20px 0;
    }
    
    .image-wrapper {
        display: none;
    }
}