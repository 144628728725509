#projects {
    background-color: #0f1f50;
    min-height: 100vh;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-title {
    color: white;
    font-size: 48px;
    text-align: center;
    margin-bottom: 60px;
    font-weight: 600;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: center;
}

.project-card {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    overflow: hidden;
    transition: transform 0.3s ease;
    width: 100%;
}

.project-card:hover {
    transform: translateY(-5px);
}

.project-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.project-image img {
    width: 80%;
    margin: auto;
    display: flex;
    padding: 10px;
    height: auto;
    object-fit: cover;
}

.project-content {
    padding: 24px;
}

.project-content h2 {
    color: white;
    font-size: 24px;
    margin: 0 0 16px 0;
}

.tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.tech-stack span {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8);
    padding: 2px 6px;
    border-radius: 2px;
    font-size: 12px;
}

.project-content p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 24px;
}

.devpost-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    width: fit-content;
    transition: background 0.3s ease;
}

.devpost-link:hover {
    background: rgba(255, 255, 255, 0.2);
}

.devpost-icon {
    width: 20px;
    height: 20px;
    filter: brightness(0) invert(1);
}

.github-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    width: fit-content;
    transition: background 0.3s ease;
}

.github-link:hover {
    background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1200px) {
    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
}

.card-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.card-link:hover {
    text-decoration: none;
}