@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600;800&display=swap');

body {
    background-color: #0f1f50;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#experience {
    padding: 60px 20px;
    width: 80%;
    margin: auto;
    background-color: #0f1f50;
    min-height: 100vh;
}

.section-title {
    color: white;
    font-size: 44px;
    font-weight: 600;
    text-align: center;
}

.timeline {
    position: relative;
    max-width: 960px;
    margin: 0 auto;
    padding-left: 50px;
}

.timeline::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
}

.timeline-item {
    position: relative;
    margin-bottom: 60px;
    padding-left: 30px;
}

.timeline-icon {
    position: absolute;
    left: -65px;
    width: 50px;
    height: 50px;
    background: #0f1f50;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.timeline-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.timeline-content {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 24px;
}

.timeline-content h2 {
    color: white;
    font-size: 20px;
    margin: 0 0 8px 0;
    font-weight: 600;
}

.timeline-content h3 {
    color: rgba(255, 255, 255, 0.6);
    font-size: 20px;
    margin: 0 0 20px 0;
    font-weight: normal;
}

.timeline-details p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 1.5;
    margin: 8px 0;
}

.special {
    color: rgb(47, 158, 73);
}

@media (max-width: 768px) {
    .timeline {
        padding-left: 30px;
    }

    .timeline-icon {
        left: -45px;
        width: 40px;
        height: 40px;
    }

    .timeline-content h2 {
        font-size: 20px;
    }

    .timeline-content h3 {
        font-size: 14px;
    }

    .timeline-details p {
        font-size: 14px;
    }
}